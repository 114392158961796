import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/outline";
import { useSelector, useDispatch } from "react-redux";
import Rating from "../../pages/RecipeDetail/components/components/Rating";
import { sendCommentAndRating } from "../../redux/recipe/recipesThunk";

export default function RatingAndCommentModal({ open, setOpen, rating }) {
  const actionButtonRef = useRef(null);
  const dispatch = useDispatch();

  const [review, setReview] = useState("");
  const [ratingVal, setRatingVal] = useState(-1);
  const [reviewError, setReviewError] = useState(false);

  const recipe = useSelector((state) => state.recipe.currentRecipe);

  const handleAction = () => {
    if (review.trim() === "") {
      setReviewError(true);
      return;
    }
    setOpen(false);
    const body = {
      recipeId: recipe._id,
      score: ratingVal,
      comment: review,
    };
    console.log(body);
    dispatch(sendCommentAndRating(body));
    setReview("");
    setRatingVal(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    setReviewError(false); // Reset the error when closing the modal
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={actionButtonRef}
        onClose={handleCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <StarIcon
                      className="h-6 w-6 text-yellow-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 mb-4"
                    >
                      {recipe.title}
                    </Dialog.Title>
                    <div className="flex flex-col w-full space-y-3 mb-4">
                      <Rating isPopup={true} notifyParent={setRatingVal} />
                      <textarea
                        onChange={(e) => {
                          setReview(e.target.value);
                          setReviewError(false); // Clear the error on user input
                        }}
                        className={`focus:outline-none focus:ring-1 w-full rounded-md p-2 ${
                          reviewError
                            ? "border-red-500 focus:border-red-500 focus:ring-red-500"
                            : "border-gray-300 focus:border-wwlOrange focus:ring-wwlOrange"
                        }`}
                        placeholder="Share your thoughts! What did you like about this recipe?"
                        rows={3}
                        value={review}
                      />
                      {reviewError && (
                        <p className="text-red-500 text-sm mt-1">
                          Please add a review to rate this recipe
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="flex items-center justify-center py-2 px-4 font-chivo text-sm whitespace-nowrap lg:mb-0 px-4 rounded-lg bg-wwlOrange border border-transparent hover:bg-wwlWhite hover:text-wwlOrange hover:border-wwlOrange text-wwlWhite py-2 px-6 transition-colors duration-300 capitalize sm:ml-4 w-full sm:w-fit"
                  ref={actionButtonRef}
                  onClick={handleAction}
                >
                  Save Rating and Review
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
